import axios from "axios"
import React from "react"
import EpisodePlayer from "../../components/EpisodePlayer"
import { Button, Form, TextInput } from "../../components/Form"
import { Column, Container, Row } from "../../components/Grid"
import Hero from "../../components/Hero"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import OutboundLink from "../../components/OutboundLink"
import Quicklinks from "../../components/Quicklinks"
import Resource from "../../components/Resource"
import Section from "../../components/Section"
import SEO from "../../components/Seo"
import { AuthConsumer } from "../../context/AuthContext"
import SchoolLookup from "../../images/theme/img-pharmacists-school.jpg"
import SubFooterImage from "../../images/theme/img-subfooter-pharmacists.jpg"
import { login } from "../../utils/auth"

class PharmacistsPage extends React.Component {
  state = {
    zip: "",
    schools: [],
    error: null,
    isClient: false,
  }

  componentDidMount() {
    this.setState({ isClient: true })
  }

  handleZipChange = (field, value) => {
    this.setState({ zip: value })
  }

  handleFormSubmit = () => {
    const { zip } = this.state

    const validated = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)

    if (!validated) {
      this.setState({
        error: "Please enter a valid zip code.",
      })
    } else {
      axios
        .get(`/api/schools.php`, {
          params: {
            zip,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(schools => {
          if (schools.data.data.length === 0) {
            this.setState({
              error: `No schools were found in ${zip}.`,
            })
          } else {
            this.setState({
              schools: schools.data.data,
              error: null,
            })
          }
        })
        .catch(error => {
          this.setState({
            error: `We encountered an issue searching for schools in ${zip}. Please try again.`,
          })
        })
    }
  }

  handleFormReset = () => {
    this.setState({
      error: null,
      schools: [],
      zip: "",
    })
  }

  render() {
    const { zip, error, schools, isClient } = this.state

    return (
      <Layout>
        <SEO title="Pharmacists" />
        {/***************  HERO ***************/}
        <Section className="hero">
          <Hero
            title="Pharmacists"
            body="The Pharmacists Teach program is designed to provide CVS Pharmacists resources to bring Dose of Knowledge into the classroom and spark the conversation around substance misuse, medication safety and mental health."
            infographic="img-hero-infographic-pharmacists.png"
            image="img-hero-pharmacists.jpg"
          />
        </Section>
        <AuthConsumer>
          {isAuthenticated => (
            <>
              {/***************  SSO CALLOUT  ***************/}
              {isAuthenticated && isClient ? null : (
                <Section className="sso__callout midgray-background">
                  <Container>
                    <button onClick={login}>
                      CVS Pharmacists, login using your CVS SSO to find a school
                      near you and prepare your lesson today!{" "}
                      <Icon name="rightarrow" />
                    </button>
                  </Container>
                </Section>
              )}

              {/***************  SCHOOL LOOKUP ***************/}
              <Section className="school-lookup midgray-background">
                <Container>
                  <Row>
                    <Column span={6}>
                      <img
                        src={SchoolLookup}
                        className="school-lookup"
                        alt="SSO School Lookup"
                      />
                    </Column>
                    <Column span={6}>
                      <h2>Find a School</h2>
                      {schools.length > 0 ? (
                        <button
                          className="as-link"
                          onClick={this.handleFormReset}
                        >
                          &lsaquo; Search again
                        </button>
                      ) : (
                        <p>
                          Type in your zip code to find schools near you that
                          may be interested in having pharmacists teach in their
                          classroom.
                        </p>
                      )}

                      {error && <p className="error-message">{error}</p>}

                      {schools.length > 0 && (
                        <div className="school-lookup-results">
                          {schools.map(school => (
                            <div className="school">
                              <h3>{school.inst}</h3>
                              <div>
                                {school.mstreet}
                                <br />
                                {school.mcity}, {school.mstate}{" "}
                                {school.mzipcode}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {schools.length === 0 && (
                        <Form onSubmit={this.handleFormSubmit}>
                          <TextInput
                            type="text"
                            label="Zip Code"
                            name="zip"
                            placeholder={
                              isAuthenticated && isClient
                                ? "Zip Code"
                                : "Login to find a school"
                            }
                            value={zip}
                            labelHidden
                            onChange={
                              !isAuthenticated ? null : this.handleZipChange
                            }
                            disabled={
                              isAuthenticated && isClient ? false : true
                            }
                          />
                          <Button
                            disabled={
                              isAuthenticated && isClient ? false : true
                            }
                            className="global__button red"
                          >
                            {isAuthenticated && isClient
                              ? "Find a school"
                              : "Login to find a school"}
                          </Button>
                        </Form>
                      )}
                    </Column>
                  </Row>
                </Container>
              </Section>

              <Section className="midgray-background">
                <Container>
                  <Row>
                    <Quicklinks
                      title="Quick Links:"
                      className="educators__quicklinks"
                      links={[
                        {
                          target: "#webinar",
                          label: "Webinar",
                        },
                        {
                          target: "#K-5",
                          label: "K–5",
                        },
                        {
                          target: "#6-8",
                          label: "6–8",
                        },
                        {
                          target: "#9-12",
                          label: "9–12",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>

              {/*************************  WEBINAR *************************/}
              <Section
                className="section--webinar midgray-background"
                id="webinar"
              >
                <Resource
                  label="Webinar"
                  image="img-pharmacists-webinar.jpg"
                  subjects={["Webinar"]}
                  title="Pharmacist Training Webinar"
                  duration="14 minutes"
                  horizontal
                  description={
                    <p>
                      Learn teaching best-practices from the experts at
                      Discovery Education and prepare to use standards-aligned
                      resources from Dose of Knowledge to spark the conversation
                      around substance misuse in the classroom.
                    </p>
                  }
                  actions={[
                    {
                      label: "Watch Webinar",
                      guidSrc: "52bb806b-35ad-4ad3-b282-5a5b12c2b150",
                      autoplay: true,
                      type: "video",
                      videoClassName: "episode-player__modal-video",
                    },
                  ]}
                  locked={isAuthenticated && isClient ? false : true}
                />
              </Section>

              {/*************** Grades K-5 DLBs *****************/}
              <Section className="midgray-background" id="K-5">
                <Container fullWidth>
                  <Row>
                    <EpisodePlayer
                      locked={isAuthenticated && isClient ? false : true}
                      playerLabelTitle="Grades K–5"
                      episodes={[
                        {
                          title: "Medication Safety (K–2)",
                          videoDuration: "5:14 min",
                          personDescription:
                            "Meet Dani, a CVS Pharmacist who will explain what medications are, how to make sure medicines are taken safely and what to do in case of an emergency.",
                          gradeRange: "Grades K–2",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will be introduced to the power of medicine and the importance of medication safety. They will learn about prescription medicine, over-the-counter medicine, and vitamins, and they will understand what to do in case of an emergency.",
                          actions: [
                            {
                              type: "download",
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Medication-Safety-Pharmacist-V5.0.pptx",
                              size: "PPT 703MB",
                              className: "episode-player__action-text",
                              logName: "Medication-Safety-K-2-Pharmacist.pptx",
                            },
                            {
                              type: "download",
                              label: "Pharmacist Guide",
                              path:
                                "/docs/CVS-Pharma-DLB-MedicationSafety-Pharmacist-V4.0.pdf",
                              size: "PDF 4.8MB",
                              className: "episode-player__action-text",
                              logName: "Medication-Safety-K-2-Pharmacist.pdf",
                            },
                          ],
                          guidSrc: "2416cbec-11ad-46f0-b885-708e7a1d1470",
                          posterSrc: "CVS_DLB1_TN.jpg",
                          listImage: "medication safety K-2.jpg",
                          listDescription:
                            "Students will be introduced to the power of medicine and the importance of medication safety.",
                        },
                        {
                          title: "Medication Safety (3–5)",
                          videoDuration: "4:28 min",
                          personDescription:
                            "Meet Kahra, a CVS Pharmacist who will help elementary students understand the differences between prescription and over-the-counter medications.",
                          gradeRange: "Grades 3–5",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will learn about the power of medicine and the importance of medication safety. They will compare and contrast prescription medicines and over-the-counter medicines, and they will understand what to do in the case of an emergency.",

                          actions: [
                            {
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-MedicationSafety-Pharmacist.pptx",
                              logName: "MedicationSafety-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 519MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Pharmacist Guide",
                              path:
                                "/files/docs/CVS-Dose-of-Knowledge-DLB-MedicationSafety-Pharmacist_Guide.pdf",
                              logName: "MedicationSafety-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 7.4MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "fd53c516-14b9-4d4a-8459-c1c23a5d8c23",
                          posterSrc: "video-kahra-960x540.jpg",
                          listImage: "medication safety 3-5.jpg",
                          listDescription:
                            "Students will learn about the power of medicine and the importance of medication safety.",
                        },
                        {
                          title: "Managing Stress (3–5)",
                          videoDuration: "5:50 min",
                          personDescription:
                            "Meet Jennifer, a CVS Pharmacist who will help students begin a conversation about mental health, understand what stress is, and provide healthy and practical daily strategies for managing stress in their lives.",
                          gradeRange: "Grades 3–5",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "After students learn about the key components of health, they will focus on mental health as they consider the causes and effects of stress. They will explore tips and strategies for supporting their own mental health, and they will apply these strategies to stressful scenarios they may experience throughout their lives.",
                          actions: [
                            {
                              type: "download",
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-ManagingStress-V4.0.pptx",
                              size: "PPT 753MB",
                              className: "episode-player__action-text",
                              logName: "Managing-Stress-Pharmacist.pptx",
                            },
                            {
                              type: "download",
                              label: "Pharmacist Guide",
                              path:
                                "/docs/CVS-Pharma-DLB-ManagingStress-Pharmacist-V3.0.pdf",
                              size: "PDF 5.2MB",
                              className: "episode-player__action-text",
                              logName: "Managing-Stress-Pharmacist.pdf",
                            },
                            {
                              type: "module",
                              moduleName: "Managing Stress Module",
                              label: "Ready-to-Use Activity",
                              path:
                                "https://app.discoveryeducation.com/learn/player/aa9cf163-a6dd-42f8-8fb9-761362e2f1c4",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "c04dbc65-ce34-4b4a-b68e-d7492eb2dd72",
                          posterSrc: "CVS_DLB2_TN.jpg",
                          listImage: "managing stress.jpg",
                          listDescription:
                            "After students learn about the key components of health, they will focus on mental health as they consider the causes and effects of stress.",
                        },
                        {
                          title: "All About Medication (3–5)",
                          videoDuration: "5:58 min",
                          personDescription:
                            "Meet Ro, a CVS Pharmacist who will talk about the power of medicine, exploring how prescription medication was discovered, how it travels through our bodies and why medicine comes in different forms.",
                          gradeRange: "Grades 3–5",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will explore how medicine is created, how it can be administered, how it travels through the body and the reasons behind its many forms.",
                          actions: [
                            {
                              type: "download",
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-AllAboutMedication-V2.0.pptx",
                              size: "PPT 794MB",
                              className: "episode-player__action-text",
                              logName: "All-About-Medication-Pharmacist.pptx",
                            },
                            {
                              type: "download",
                              label: "Pharmacist Guide",
                              path:
                                "/docs/CVS-Pharma-DLB-AllAboutMedication-Pharmacist-V1.1.pdf",
                              size: "PDF 6.3MB",
                              className: "episode-player__action-text",
                              logName: "All-About-Medication-Pharmacist.pdf",
                            },
                          ],
                          guidSrc: "99fda6b5-a752-463b-a330-8c103ff0b323",
                          posterSrc: "CVS_DLB3_TN.jpg",
                          listImage: "all about medication.jpg",
                          listDescription:
                            "Students will explore how medicine is created, how it can be administered, how it travels through the body and the reasons behind its many forms.",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>

              {/*************** Grades 6-8 DLBs *****************/}
              <Section className="midgray-background" id="6-8">
                <Container fullWidth>
                  <Row>
                    <EpisodePlayer
                      locked={isAuthenticated && isClient ? false : true}
                      playerLabelTitle="Grades 6–8"
                      episodes={[
                        {
                          title: "Mental Health (6–12)",
                          videoDuration: "4:47 min",
                          personDescription:
                            "Meet James, a CVS pharmacist who will talk to students about benzodiazepines, a class of drugs that may be used to treat the most severe types of anxiety, and how they can be dangerous if misused or combined with other substances.",
                          gradeRange: "Grades 6–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body. Students will learn about the role prescription medications can play in the treatment of anxiety and depression and review the risks associated with misusing prescription medications or other substances.",
                          actions: [
                            {
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-MentalHealth-Pharmacist.pptx",
                              logName: "MentalHealth-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 606MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Pharmacist Guide",
                              path:
                                "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Pharmacist_Guide.pdf",
                              logName: "MentalHealth-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 10MB",
                              className: "episode-player__action-text",
                            },
                            {
                              type: "module",
                              moduleName: "Mental Health Module",
                              label: "Ready-to-Use Activity",
                              path:
                                "https://app.discoveryeducation.com/learn/player/ad14fa9a-ddfd-4d99-9568-0d6604032b0d",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                          posterSrc: "video-james-960x540.jpg",
                          listImage: "mental health.jpg",
                          listDescription:
                            "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body.",
                        },
                        {
                          title: "Peer Pressure (6–12)",
                          videoDuration: "4:24 min",
                          personDescription:
                            "Meet Lance, a CVS pharmacist who will talk to students about peer pressure, refusal tactics, and how they can positively impact their peers.",
                          gradeRange: "Grades 6–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "In this Digital Lesson Bundle, students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                          actions: [
                            {
                              label:
                                "Digital Lesson for Traditional Classroom Setting",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS_DLB_Peer_Pressure_Pharmacist.pptx",
                              logName:
                                "Responding to Peer Pressure.pptx - Pharmacists - Classroom",
                              type: "download",
                              size: "PPT 273MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Pharmacist Guide for Traditional Classroom Setting",
                              path:
                                "/docs/CVS-Pharma-DLB-Responding_to_Peer_Pressure-Pharmacists.pdf",
                              logName:
                                "Responding to Peer Pressure.pdf - Pharmacists - Classroom",
                              type: "download",
                              size: "PDF 3MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                          posterSrc: "img-toolkit-video-peer-pressure.jpg",
                          listImage: "peer preassure.jpg",
                          listDescription:
                            "Students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                        },
                        {
                          title: "Prescription Stimulants (6–12)",
                          videoDuration: "4:05 min",
                          personDescription:
                            "Meet Tyler, a CVS pharmacist who will talk to students about prescription stimulants, how they work, and how it can be dangerous to misuse them.",
                          gradeRange: "Grades 6–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD. Students will explore the dangers associated with prescription stimulant misuse, the effects misuse can have on the brain and body, and they will identify where they can turn for help if needed.",
                          actions: [
                            {
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Pharmacist.pptx",
                              logName: "PrescriptionStimulants-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 440MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Pharmacist Guide",
                              path:
                                "/docs/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Pharmacist_Guide.pdf",
                              logName:
                                "PrescriptionStimulants-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 6.1MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                          posterSrc: "video-tyler-960x540.jpg",
                          listImage: "prescription stimulants.jpg",
                          listDescription:
                            "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD.",
                        },
                        {
                          title: "Opioid Use and Misuse (6–8)",
                          videoDuration: "5:08 min",
                          personDescription:
                            "Meet Vicki, a CVS pharmacist who will talk to students about ways to support those who may be struggling with substance misuse or substance use disorder.",
                          gradeRange: "Grades 6–8",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will learn about opioids, the effects of these drugs on the human brain, and the endorphin-like effects on the human body. Students will compare the differences between prescription medications and illicit drugs.",
                          actions: [
                            {
                              label:
                                "Digital Lesson for Traditional Classroom Setting",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS_DLB_Opioids_Use_Misuse_Pharmacist_Classroom.pptx",
                              logName:
                                "Opioid Use and Misue.pptx - Pharmacists - Classroom",
                              type: "download",
                              size: "PPT 296MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Pharmacist Guide for Traditional Classroom Setting",
                              path:
                                "/docs/CVS-DoK-DLB-Opiod-Use-and-Misuse-Pharmacist-Classroom.pdf",
                              logName:
                                "Opioid Use and Misue.pdf - Pharmacists - Classroom",
                              type: "download",
                              size: "PDF 3.7MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Digital Lesson for Non-Classroom Setting (e.g. virtual, auditorium, non-school organization)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS_DLB_Opioids_Use_Misuse_Pharmacist_Non_Classroom.pptx",
                              logName:
                                "Opioid Use and Misue.pptx - Pharmacists - Non-Classroom",
                              className: "max__width",
                              type: "download",
                              size: "PPT 320MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Pharmacist Guide for Non-Classroom Setting",
                              path:
                                "/docs/CVS-DoK-DLB-Opiod-Use-and-Misuse-Pharmacist-Non-Classroom.pdf",
                              logName:
                                "Opioid Use and Misue.pdf - Pharmacists - Non-Classroom",
                              type: "download",
                              size: "PDF 3.7MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "2526ede5-f86a-4e83-bf5f-6f318b69c9f9",
                          posterSrc: "img-toolkit-video-help-around.jpg",
                          listImage: "opioid use and misuse.jpg",
                          listDescription:
                            "Students will learn about opioids, the effects of these drugs on the human brain, and the endorphin-like effects on the human body.",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>

              {/*************** Grades 9-12 DLBs *****************/}
              <Section className="midgray-background" id="9-12">
                <Container fullWidth>
                  <Row>
                    <EpisodePlayer
                      locked={isAuthenticated && isClient ? false : true}
                      playerLabelTitle="Grades 9–12"
                      episodes={[
                        {
                          title: "Mental Health (6–12)",
                          videoDuration: "4:47 min",
                          personDescription:
                            "Meet James, a CVS pharmacist who will talk to students about benzodiazepines, a class of drugs that may be used to treat the most severe types of anxiety, and how they can be dangerous if misused or combined with other substances.",
                          gradeRange: "Grades 6–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body. Students will learn about the role prescription medications can play in the treatment of anxiety and depression and review the risks associated with misusing prescription medications or other substances.",
                          actions: [
                            {
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-MentalHealth-Pharmacist.pptx",
                              logName: "MentalHealth-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 606MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Pharmacist Guide",
                              path:
                                "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Pharmacist_Guide.pdf",
                              logName: "MentalHealth-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 10MB",
                              className: "episode-player__action-text",
                            },
                            {
                              type: "module",
                              moduleName: "Mental Health Module",
                              label: "Ready-to-Use Activity",
                              path:
                                "https://app.discoveryeducation.com/learn/player/ad14fa9a-ddfd-4d99-9568-0d6604032b0d",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                          posterSrc: "video-james-960x540.jpg",
                          listImage: "mental health.jpg",
                          listDescription:
                            "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body.",
                        },
                        {
                          title: "Peer Pressure (6–12)",
                          videoDuration: "4:24 min",
                          personDescription:
                            "Meet Lance, a CVS pharmacist who will talk to students about peer pressure, refusal tactics, and how they can positively impact their peers.",
                          gradeRange: "Grades 6–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "In this Digital Lesson Bundle, students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                          actions: [
                            {
                              label:
                                "Digital Lesson for Traditional Classroom Setting",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS_DLB_Peer_Pressure_Pharmacist.pptx",
                              logName:
                                "Responding to Peer Pressure.pptx - Pharmacists - Classroom",
                              type: "download",
                              size: "PPT 273MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Pharmacist Guide for Traditional Classroom Setting",
                              path:
                                "/docs/CVS-Pharma-DLB-Responding_to_Peer_Pressure-Pharmacists.pdf",
                              logName:
                                "Responding to Peer Pressure.pdf - Pharmacists - Classroom",
                              type: "download",
                              size: "PDF 3MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                          posterSrc: "img-toolkit-video-peer-pressure.jpg",
                          listImage: "peer preassure.jpg",
                          listDescription:
                            "Students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                        },
                        {
                          title: "Prescription Stimulants (6–12)",
                          videoDuration: "4:05 min",
                          personDescription:
                            "Meet Tyler, a CVS pharmacist who will talk to students about prescription stimulants, how they work, and how it can be dangerous to misuse them.",
                          gradeRange: "Grades 6–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD. Students will explore the dangers associated with prescription stimulant misuse, the effects misuse can have on the brain and body, and they will identify where they can turn for help if needed.",
                          actions: [
                            {
                              label: "Digital Lesson",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Pharmacist.pptx",
                              logName: "PrescriptionStimulants-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 440MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Pharmacist Guide",
                              path:
                                "/docs/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Pharmacist_Guide.pdf",
                              logName:
                                "PrescriptionStimulants-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 6.1MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                          posterSrc: "video-tyler-960x540.jpg",
                          listImage: "prescription stimulants.jpg",
                          listDescription:
                            "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD.",
                        },
                        {
                          title: "Opioids and the Brain (9–12)",
                          videoDuration: "3:53 min",
                          personDescription:
                            "Meet Fernando, a CVS pharmacist who will talk to students about opioids, the risk associated with substance misuse, and what students should do if they are prescribed an opioid.",
                          gradeRange: "Grades 9–12",
                          lessonDuration: "45-60 min",
                          lessonDescription:
                            "Students will receive an overview of what opioids are and examine the short-term and long-term effects they have on the brain and body. Students will learn about substance misuse and substance use disorder.",
                          actions: [
                            {
                              label:
                                "Digital Lesson for Traditional Classroom Setting",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS_DLB_Opioids_Brain_Pharmacist_Classroom.pptx",
                              logName:
                                "Opioids and the Brain.pptx - Pharmacists - Classroom",
                              type: "download",
                              size: "PPT 245MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Pharmacist Guide for Traditional Classroom Setting",
                              path:
                                "/docs/CVS-DoK-DLB-Opiods-and-the-Brain-Pharmacist-Classroom.pdf",
                              logName:
                                "Opioids and the Brain.pdf - Pharmacists - Classroom",
                              type: "download",
                              size: "PDF 5.9MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Digital Lesson for Non-Classroom Setting (e.g. virtual, auditorium, non-school organization)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS_DLB_Opioids_Brain_Pharmacist_Non_Classroom.pptx",
                              logName:
                                "Opioids and the Brain.pptx - Pharmacists - Non-Classroom",
                              className: "max__width",
                              type: "download",
                              size: "PPT 257MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Pharmacist Guide for Non-Classroom Setting",
                              path:
                                "/docs/CVS-DoK-DLB-Opiods-and-the-Brain-Pharmacist-Non-Classroom.pdf",
                              logName:
                                "Opioids and the Brain.pdf - Pharmacists - Non-Classroom",
                              type: "download",
                              size: "PDF 4.6MB",
                              className: "episode-player__action-text",
                            },
                            {
                              type: "module",
                              moduleName: "Opioids and the Brain Module",
                              label: "Ready-to-Use Activity",
                              path:
                                "https://app.discoveryeducation.com/learn/player/66f5f28c-f2e4-4cb0-b4f9-218994b74b39",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "4d8892c2-8bb3-4e13-a6d4-3272f313bcae",
                          posterSrc: "img-toolkit-video-opioids-effects.jpg",
                          listImage: "opiods and brain.jpg",
                          listDescription:
                            "Students will receive an overview of what opioids are and examine the short-term and long-term effects they have on the brain and body.",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>
            </>
          )}
        </AuthConsumer>
        {/***************  ABOVE FOOTER CALLOUT ***************/}
        <Section className="subfooter">
          <Container>
            <Row>
              <Column span={4}>
                <img
                  src={SubFooterImage}
                  className="subfooter-image"
                  alt="About Pharmacists Teach"
                />
              </Column>
              <Column span={8}>
                <h3>About Pharmacists Teach</h3>
                <p>
                  CVS Health created <strong>Pharmacists Teach</strong> in 2015
                  to bring pharmacists into the classroom to help educate the
                  public about the challenges surrounding substance misuse. The{" "}
                  <strong>Pharmacists Teach</strong> program has now been
                  expanded to include <strong>Dose of Knowledge</strong>, a
                  program created in partnership with Discovery Education to
                  provide standards-aligned resources to educators and
                  pharmacists across the U.S. The{" "}
                  <strong>Pharmacists Teach</strong> program strives to reach
                  nearly 2M students by 2023 and to empower students across the
                  country to make healthy choices to prevent the misuse of
                  prescription and illicit drugs.
                </p>
                <OutboundLink to="https://cvshealth.com/social-responsibility/corporate-social-responsibility/pharmacists-teach">
                  <button className="global__button black">Learn More</button>
                </OutboundLink>
              </Column>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default PharmacistsPage
